import axios from 'axios'
import store from '@/store'
import { Loading, Message } from 'element-ui';

const service = axios.create({
  baseURL: 'https://a.qingyuweilai.com/api',//process.env.VUE_APP_apiHost,  //基础路径
  timeout: 20000, //超时时间 20秒还是没有请求成功，报timeout
})
//请求拦截器和响应拦截器
//给axios添加额外的功能或者修改对应的请求头
//请求拦截器
service.interceptors.request.use(
  (config) => {
    let token = store.state.user.token
    if (token) {
      config.headers.token = token
    }

    return config
  },
);

//响应拦截器
service.interceptors.response.use(
  async (response) => {
    // console.log(response, 333);
    if (response.data.code !== 0) {
      if (response.data instanceof Blob) {
        const data = await featchBlobUrl(response.data);
        if (data && data.msg) {
          if(data.code !== 0){
            Message.error(data.msg);
          }
        }
      } else {
        Message.error(response.data.msg);
      }
    }
    return response
  },
  (error) => {
    //发请求失败，统一处理
    // alert('发送ajax请求失败' + error.message || '未知错误')
    return new Promise(() => { }) //中断promise链
  }
);

function featchBlobUrl(data) {
  const url = URL.createObjectURL(data)
  return fetch(url)
    .then((response) => {
      // 处理响应
      return response.json();
    })
    .then((data) => {
      // 释放 URL 对象
      URL.revokeObjectURL(url);
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
}

export default service



