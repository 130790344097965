<template>
  <!-- https://nodestatic.fbstatic.cn/weblts_spa_online/page/assets/fenxiao/header/logo-new.png -->
  <!-- https://fb.fbstatic.cn/api/ape-images/16482511087ffb0.jpg -->
  <div class="header_out">
    <div class="container">
      <div class="logo">
        <!-- <router-link to="/home"> -->
          <img class="logo_img" src="./images/logo.png" alt="" @click="toHome()"/>
        <!-- </router-link> -->
      </div>
      <div class="tab">
        <router-link to="/home">
          <span class="current">课程</span>
        </router-link>
        <!-- <span>课程</span> -->
        <!-- <span>题库</span>
        <span>师资</span>
        <span>下载</span>
        <span>推介项目</span>
        <span>投资者关系</span> -->
      </div>

      <!-- 未登录状态 -->
      <div class="login_btn" v-if="!$store.state.user.token">
        <span @click="openLogin()">登录</span>
      </div>

      <!-- 登录状态 -->
      <div class="avatar" v-else>
        <img
          @click.stop="openExit()"
          class="avatar_img"
          :src="$store.state.user.userInfo.avatarUrl"
          alt=""
        />
      </div>

      <!-- 登录后会员标识 -->
      <div class="vip_flag" v-if="$store.state.user.userInfo.vipLevelId">
        <span><img src="./images/hg.png" alt=""></span>
        <span>青鱼大会员</span>
      </div>

      <!-- vip会员按钮 -->
      <div class="vip_btn">
        <span @click="toMemberPage()">VIP会员</span>
      </div>

      

      <!-- 退出登录弹窗 -->
      <div class="exit_dialog" @click.stop="() => {}">
        <div class="exit_dialog_content">
          <div class="top">
            <div class="left">
              <img
                style="width: 50px; height: 50px"
                class="avatar_img"
                :src="$store.state.user.userInfo.avatarUrl"
                alt=""
              />
            </div>
            <!-- 会员样式 -->
            <div class="right" v-if="$store.state.user.userInfo.vipLevelId">
              <div class="name">
                <span>{{ $store.state.user.userInfo.nickname }}</span>
                <!-- <img
                  style="width: 20px; height: 20px"
                  class="avatar_img"
                  src="./images/hy.png"
                  alt=""
                /> -->
              </div>
              <div class="date">
                有效期至{{ $store.state.user.userInfo.vipExpireTime.split(" ")[0]}}
              </div>
            </div>
            <!-- 非会员样式 -->
            <div class="right" v-else>
              <span>{{ $store.state.user.userInfo.nickname }}</span>
            </div>
          </div>
          <div class="bottom">
            <div>
              <img
                style="width: 18px; height: 18px"
                src="./images/dd.png"
                alt=""
              />
              <span @click="toMyOrder()">我的订单</span>
            </div>
            <div>
              <img
                style="width: 18px; height: 18px"
                src="./images/tc.png"
                alt=""
              />
              <span @click="quit()">退出登录</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 确认退出弹窗 -->
      <div class="confirm_exit_dialog">
        <div class="confirm_exit_dialog_content">
          <div class="tip">请确认是否退出登录?</div>
          <div class="confirm_cancle_btn">
            <span @click="confirm_btn()">确定</span>
            <span @click="cancle_btn()">取消</span>
          </div>
        </div>
      </div>

      <!-- 登录账号弹窗 -->
      <div class="login_dialog" @click="closeLogin($event)">
        <div class="login_dialog_content">
          <div class="title">登录</div>
          <div class="field">
            <img src="./images/sjh.png" alt="" />
            <input v-model="phone" type="text" placeholder="请输入手机号" />
          </div>
          <div class="field field_yzm">
            <img src="./images/yzm.png" alt="" />
            <input v-model="code" type="text" placeholder="请输入验证码" />
            <span @click="getCode($event)" class="code_btn">获取验证码</span>
            <!-- <span style="color:#999" class="code_btn_disabled">(59s)后再次获取</span> -->
          </div>
          <div class="send_message" v-show="tipShow">
            <img src="./images/yfs.png" alt="" />
            <span>验证码已发送，可能会有延后，请耐心等待</span>
            <!-- 验证码有误提示 -->
            <!-- <span style="color: #ED0000;">验证码有误</span> -->
          </div>
          <div class="dialog_login_btn" @click="loginBtn">
            <span>登录</span>
          </div>
          <div class="agreement">
            <span @click="agreeEvent()">
              <img v-if="imgShow" src="./images/xy2.png" />
              <img v-if="!imgShow" src="./images/xyxz.png" />
            </span>
            <span>我已阅读</span>
            <a :href="url2" target="_blank">《用户协议》</a>
            <span>和</span>
            <a :href="url1" target="_blank">《隐私策略》</a>
            <span></span>
          </div>
        </div>
      </div>

      <!-- 提示 -->
      <div class="clause_tip">
        <span>请勾选服务条款</span>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      phone: "",
      code: "",
      imgShow: true,
      tipShow: false,
      clauseShow: false,
      url1: '',
      url2: '',
      url3: '',
    };
  },
  computed:{
    ...mapState({
      urlList: (state) => state.option.urlList,
    }),
  },
  async mounted() {
    // console.log(this.$store.state.user.token);
    if (this.$store.state.user.token) {
      try {
        await this.$store.dispatch("getUserInfo");
      } catch (error) {
        console.log(error.message);
      }
    }
    // 取消显示弹窗
    this.hidDialog()
    this.getUrlList();
  },
  destroyed() {
    window.removeEventListener("click", this.hidDialogCallback);
  },
  methods: {
    async toHome(){
      await this.$router.push('/home');
      location.reload();
    },
    async getUrlList(){
      await this.$store.dispatch('getUrlList', { code: "protocolAddress" });
      this.urlList.forEach((item,index)=>{
        if(item.code == "privacy"){
          this.url1 = item.ext;
        }
        if(item.code == "user"){
          this.url2 = item.ext;
        }
        if(item.code == "member"){
          this.url3 = item.ext;
        }
      })
    },
    hidDialog() {
      window.addEventListener('click', this.hidDialogCallback, true);
    },
    hidDialogCallback() {
      let dom = document.querySelector(".exit_dialog");
      dom.style.display = "none";
    },
    toMemberPage() {
      this.$router.push("/order/member");
    },
    async getCode(e) {
      let el = e.target;
      let timer = "";
      let index = 59;
      let obj = {
        phone: this.phone,
        scene: 1,
      };
      if (el.innerHTML == "获取验证码") {
        try {
          const result = await this.$store.dispatch("getCode", obj);
        } catch (error) {
          console.log(error.message + "手机验证码获取失败");
        }
        el.innerHTML = "(59s)后再次获取";
        el.style.color = "#999";
        this.tipShow = true;
        timer = setInterval(() => {
          el.innerHTML = `(${--index}s)后再次获取`;
          if (index < 0) {
            el.innerHTML = "获取验证码";
            el.style.color = "#2E58EB";
            this.tipShow = false;
            index = 59;
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    async loginBtn() {
      let dom = document.querySelector(".clause_tip");
      if (this.imgShow) {
        dom.style.display = "block";
        setTimeout(() => {
          dom.style.display = "none";
        }, 1000);
        return;
      }

      let obj = {
        phone: this.phone,
        code: this.code,
        deviceId: uuidv4(),
      };
      try {
        const result = await this.$store.dispatch("userLogin", obj);
        if (result.code == 0) {
          let dom = document.querySelector(".login_dialog");
          dom.style.display = "none";
          await this.$store.dispatch("getUserInfo");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    closeLogin(e) {
      // console.log(e.target)
      let dom = document.querySelector(".login_dialog");
      if (e.target.className == "login_dialog") {
        dom.style.display = "none";
      }
    },
    openExit() {
      let dom = document.querySelector(".exit_dialog");
      dom.style.display = dom.style.display == "block" ? "none" : "block";
    },
    openLogin() {
      let dom = document.querySelector(".login_dialog");
      dom.style.display = "block";
    },
    agreeEvent() {
      this.imgShow = !this.imgShow;
    },
    quit() {
      let dom = document.querySelector(".confirm_exit_dialog");
      dom.style.display = "block";
    },
    async confirm_btn() {
      try {
        await this.$store.dispatch("userLogout");
      } catch (error) {
        console.log(error.message);
      }
      let dom = document.querySelector(".confirm_exit_dialog");
      dom.style.display = "none";
      let dom2 = document.querySelector(".exit_dialog");
      dom2.style.display = "none";
      await this.$router.push("/").catch(() => {});
      location.reload();
    },
    cancle_btn() {
      let dom = document.querySelector(".confirm_exit_dialog");
      dom.style.display = "none";
    },
    toMyOrder() {
      this.$router.push("/order/my");
      let dom = document.querySelector(".exit_dialog");
      dom.style.display = "none";
    },
  },
};
</script>

<style  lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.header_out {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  font-family: PingFang SC;
  position: relative;
  z-index: 999;
  background-color: #fff;

  .container {
    // overflow: hidden;
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    position: relative;
    .logo {
      float: left;
      width: 200px;
      .logo_img {
        width: 134px;
        height: 24px;
        margin: 15px 0;
      }
    }
    .tab {
      float: left;
      span {
        display: inline-block;
        margin-right: 45px;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #2e58eb;
        height: 60px;
        line-height: 60px;
        position: relative;
        &.current {
          &::after {
            content: "";
            display: inline-block;
            width: 26px;
            height: 3px;
            background-color: #2e58eb;
            position: absolute;
            bottom: 0;
            left: 4px;
            border-radius: 41px;
          }
        }
      }
    }
    .vip_flag{
      margin: 19px 40px 19px 3px;
      float: right;
      width: 87px;
      height: 22px;
      border-radius: 11px;
      background: linear-gradient(111deg, #FBDC9E 7.66%, #FFF9E9 88.17%);
      span{
        display: inline-block;
        float: left;
        &:first-child{
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #fff;
          margin: 2px 4px;
          text-align: center;
          img{
            width: 15px;
            position: relative;
            top: -2px;
          }
        }
        &:last-child{
          color: #82682D;
          font-size: 11px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
    .vip_btn {
      float: right;
      margin: 16px 0;
      margin-right: 10px;
      width: 80px;
      height: 26px;
      border-radius: 74px;
      border: 1px solid #f90;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
      span {
        color: #f90;
        font-size: 14px;
        line-height: 14px;
      }
    }
    .login_btn {
      float: right;
      margin: 16px 0;
      width: 62px;
      height: 28px;
      line-height: 28px;
      border-radius: 34px;
      background: #f0f2f9;
      text-align: center;
      cursor: pointer;
      span {
        color: #2e58eb;
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
      }
    }
    .avatar {
      float: right;
      .avatar_img {
        width: 36px;
        height: 36px;
        margin-top: 10px;
        border-radius: 50%;
      }
      //display: none;
    }
    .exit_dialog {
      display: none;
      border-radius: 12px;
      width: 260px;
      height: 179px;
      position: absolute;
      right: 0;
      top: 73px;
      z-index: 999;
      background: #fff;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.06);
      .exit_dialog_content {
        padding: 20px;
        .top {
          overflow: hidden;
          border-bottom: 1px solid rgba(0, 0, 0, 0.04);
          .left {
            float: left;
            padding: 0 14px 20px 0;
            img{
              border-radius: 50%;
            }
          }
          .right {
            float: left;
            .name {
              span {
                color: #e96e14;
                font-family: PingFang SC;
                font-size: 16px;
                line-height: 16px;
                padding-right: 6px;
              }
              img {
                width: 20px;
                height: 20px;
                position: relative;
                top: 5px;
              }
            }
            .date {
              color: #333;
              font-family: PingFang SC;
              font-size: 11px;
              padding-top: 8px;
            }
            > span {
              display: inline-block;
              padding-top: 17px;
              font-family: PingFang SC;
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
        .bottom {
          cursor: pointer;
          div {
            padding-top: 11px;
            img {
              position: relative;
              top: 4px;
              padding-right: 9px;
            }
            span {
              color: #333;
              font-family: PingFang SC;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }
    }

    .confirm_exit_dialog {
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      .confirm_exit_dialog_content {
        width: 320px;
        height: 140px;
        border-radius: 12px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 24px 17px 34px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        .tip {
          color: #333;
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 47px;
        }
        .confirm_cancle_btn {
          font-size: 14px;
          line-height: 14px;
          text-align: right;
          cursor: pointer;
          span {
            display: inline-block;
            width: 94px;
            height: 30px;
            border-radius: 29px;
            line-height: 30px;
            text-align: center;
            &:first-child {
              color: #fff;
              background: var(
                ---,
                linear-gradient(90deg, #96dafe 0%, #2e58eb 100%)
              );
              margin-right: 16px;
            }
            &:last-child {
              color: #999;
              background-color: #f0f5ff;
            }
          }
        }
      }
    }

    .login_dialog {
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      .login_dialog_content {
        width: 246px;
        // height: 356px;
        border-radius: 8px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 29px;
        // box-sizing: border-box;
        .title {
          text-align: center;
          color: #2e58eb;
          font-size: 20px;
          line-height: 20px;
          font-size: 24px;
          font-weight: 500;
          padding-bottom: 54px;
        }
        .field {
          border-bottom: 1px solid #ededed;
          margin-bottom: 51px;
          position: relative;
          font-size: 12px;
          line-height: 12px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            top: 1px;
          }
          input {
            border: 0;
            outline: none;
            padding-left: 11px;
            padding-bottom: 10px;
            width: 90%;
            height: 12px;
            &::-webkit-input-placeholder {
              color: #ccc;
              font-size: 12px;
              line-height: 12px;
              font-weight: 400;
              font-family: PingFang SC;
            }
          }
          span {
            position: absolute;
            right: 0;
            bottom: 12px;
            color: #2e58eb;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
        }
        .field_yzm {
          input {
            width: 50%;
          }
          margin-bottom: 54px;
          span {
            cursor: pointer;
          }
        }
        .send_message {
          color: #2e58eb;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          position: absolute;
          bottom: 137px;
          img {
            width: 12px;
            height: 12px;
            position: relative;
            top: 3px;
            margin-right: 6px;
          }
        }
        .dialog_login_btn {
          width: 100%;
          height: 36px;
          border-radius: 129px;
          background: var(
            ---,
            linear-gradient(90deg, #96dafe 0%, #2e58eb 100%)
          );
          text-align: center;
          margin-bottom: 24px;
          font-size: 16px;
          line-height: 36px;
          color: #fff;
          cursor: pointer;
        }
        .agreement {
          font-size: 10px;
          line-height: 12px;
          img {
            width: 12px;
            height: 12px;
            position: relative;
            top: 3px;
            margin-right: 6px;
          }
          span {
            height: 12px;
            font-size: 10px;
            line-height: 12px;
            font-weight: 400;
            &:nth-child(2n) {
              color: #ccc;
            }
          }
          a{
            &:nth-child(2n + 1) {
              text-decoration: none;
              color: #2e58eb;
            }
          }
        }
      }
    }

    .clause_tip {
      display: none;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 188px;
      height: 50px;
      line-height: 50px;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      z-index: 1000;
    }
  }
}
</style>
