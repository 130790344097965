<template>
  <div class="footer_out">
    <div class="line"></div>
    <div class="container">
      <div class="content">
        <div class="tab">
          <!-- <span class="current">关于青鱼未来</span> -->
          <a href="https://baike.baidu.com/item/%E9%9D%92%E9%B1%BC%E6%9C%AA%E6%9D%A5" target="_blank">关于青鱼未来</a>
          <a class="vertical">|</a>
          <a :href="url1" target="_blank">隐私政策</a>
          <a class="vertical">|</a>
          <a :href="url2" target="_blank">用户协议</a>
          <a class="vertical">|</a>
          <a :href="url3" target="_blank">会员协议</a>
          <!-- <a class="vertical">|</a>
          <a>下载APP</a> -->
        </div>
        <div class="record">
          <a>京ICP备2022033006号</a>
          <a class="vertical">|</a>
          <img src="./images/jh.png" alt="">
          <a>京公安备 11010802042205</a>
          <a class="vertical">|</a>
          <!-- <img style="width: 29px;height:29px" src="./images/ewm.jpg" alt=""> -->
          <img class="hover_img" src="./images/ewm.jpg" alt="" @mouseenter="openImg()" @mouseleave="closeImg()">
          <a>添加客服小姐姐 开心聊天 高效备考</a>
          <div class="hover_img_div">
            <img src="./images/ewm.jpg" alt="">
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'Footer',
  data(){
    return {
      url1: '',
      url2: '',
      url3: '',
    }
  },
  computed:{
    ...mapState({
      urlList: (state) => state.option.urlList,
    }),
  },
  mounted(){
    this.getUrlList();
  },
  methods: {
    async getUrlList(){
      await this.$store.dispatch('getUrlList', { code: "protocolAddress" });
      this.urlList.forEach((item,index)=>{
        if(item.code == "privacy"){
          this.url1 = item.ext;
        }
        if(item.code == "user"){
          this.url2 = item.ext;
        }
        if(item.code == "member"){
          this.url3 = item.ext;
        }
      })
    },
    openImg(){
      document.querySelector('.hover_img_div').style.display = 'block';
    },
    closeImg(){
      document.querySelector('.hover_img_div').style.display = 'none';
    }
  }
}
</script>

<style  lang="less" scoped>
.footer_out{
  background-color: #403E3F;
  text-align: center;
  .line{
    height: 1px;
    background-color: #595959;
    position: relative;
    top: 74px;
  }
  .container{
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    height: 150px;
    .tab{
      height: 75px;
      line-height: 75px;
      a{
        display: inline-block;
        color: #B3B2B2;
        font-family: PingFang SC;
        font-size: 14px;
        text-decoration:none;
        cursor: pointer;
        &.vertical{
          font-size: 14px;
          width: 16px;
          height: 16px;
          padding: 0 17px;
        }
        &.current{
          color: #fff;
        }
      }
    }
    
    .record{
      height: 75px;
      line-height: 75px;
      color: #B3B2B2;
      font-family: PingFang SC;
      font-size: 12px;
      position: relative;
      img{
        width: 20px;
        height: 20.752px;
        position: relative;
        top: 6px;
        margin-right: 5px;
      }
      a{
        display: inline-block;
        cursor: pointer;
        text-decoration:none;
        &.vertical{
          font-size: 14px;
          width: 16px;
          height: 16px;
          padding: 0 10px;
        }
      }
      .hover_img{
        cursor: pointer;
      }

      .hover_img_div{
        display: none;
        width: 100px;
        height: 100px;
        position: absolute;
        top: -80px;
        left: 640px;
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
    
  }
}
</style>
