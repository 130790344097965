<template>
  <div id="app">
    <!-- <div style="width: 300px; height: 100px; background: red;"></div> -->
    <Header></Header>
    <keep-alive include="Home">
      <router-view></router-view>
    </keep-alive>
    <Footer v-if="$route.path == '/home' || $route.path == '/order/member' || $route.path == '/course/keji'"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
}
#app {
  height: 100%;
  background-color: #F5F6F6;
  min-width: 1300px;
}
</style>
