import {reqCourseList,reqMyCourseList} from '@/api'
const state = {
  courseList: [],
  type: 1
}
const mutations = {
  RECEIVE_COURSE_LIST(state,list){
    state.courseList = list
    state.type = 1
  },
  RECEIVE_MY_COURSE_LIST(state,list){
    state.courseList = list
    state.type = 2
  },
}
const actions = {
  async getCourseList({commit},obj){
    const result = await reqCourseList(obj)
    if(result.code === 0){
      commit('RECEIVE_COURSE_LIST',result.result.list)
      return result.result.totalCount
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getMyCourseList({commit},obj){
    const result = await reqMyCourseList(obj)
    if(result.code === 0){
      commit('RECEIVE_MY_COURSE_LIST',result.result)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },

}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}