import  specialAjax  from './specialAjax';

// 获取订单支付二维码
export const reqQRCode = (data) => {
  return specialAjax({
    url:`/web/order/create`,
    method:'post',
    data: data,
    responseType: "blob",
  })
}

// 继续支付 获取订单支付二维码
export const reqContinuePay = (data) => {
  return specialAjax({
    url:`/web/order/continue-pay`,
    method:'get',
    params: data,
    responseType: "blob",
  })
}