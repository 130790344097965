import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes';
import store from '@/store';
Vue.use(VueRouter);

const router = new VueRouter({
  // 配置路由器路由的地方
  routes,
  // 配置路由跳转后滚动的位置为顶部
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

//使用全局路由守卫去监测路由的变化
router.beforeEach((to,from,next)=>{
	  // to  值为对象，包含跳转后的路由的信息
    // from   值为对象，包含跳转前的路由的信息
    // next   值为函数，next()表示可以跳转到任意页面，如果传参数，传的是对象，表示跳转到指定页面，例如next({path:"/login"})表示跳转到登录页
    //如果去往登录页，那么将来时的路径存储到实例上
    if (to.path === '/home' || to.path === '/order/member') {
      next();
      return;
    }
    if(store.state.user.token){
      next()
    }else{
      next('/home')
    }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default router