import axios from 'axios'
import store from '@/store'
import { Loading, Message } from 'element-ui';

const service = axios.create({
  baseURL: process.env.VUE_APP_apiHost,  //基础路径
  timeout: 20000, //超时时间 20秒还是没有请求成功，报timeout
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})
//请求拦截器和响应拦截器
//给axios添加额外的功能或者修改对应的请求头
//请求拦截器
service.interceptors.request.use(
  (config)=>{
    //请求头添加信息
    //config 请求报文
    //再请求头当中添加临时标识
    // let userTempId = store.state.user.userTempId
    // if(userTempId){
    //   config.headers.userTempId = userTempId
    // }

    let token = store.state.user.token
    if(token){
      config.headers.token = token
    }
    
    return config
  },
);

//响应拦截器
service.interceptors.response.use(
  (response) => {
    if(response.data.code !== 0){
      Message.error(response.data.msg)
    }
    return response.data 
  },
  (error) =>{
    //发请求失败，统一处理
    // alert('发送ajax请求失败' + error.message || '未知错误')
    return new Promise(() => {}) //中断promise链
  }
);

export default service 



