import  Ajax  from './ajax';

//根据token获取用户信息
// token不是在接口函数的参数里面，而是在请求头当中携带
export const reqGetUserInfo = () => {
  return Ajax({
    url:'/app/student/profile',
    method:'get'
  })
}

//获取验证码
export const reqPhoneCode = (obj) => {
  return Ajax({
    url:`/app/web/sendMsg`,
    method:'post',
    data: obj
  })
}

//用户登录
export const reqUserLogin = (obj) => {
  return Ajax({
    url:'/app/web/login',
    method:'post',
    data:obj
  })
}

//退出登录
export const reqUserLogout = () => {
  return Ajax({
    url:'/app/web/logout',
    method:'post'
  })
}


// 课程列表
export const reqCourseList = (obj) => {
  return Ajax({
    url:'/app/course/dlc/list',
    method:'get',
    params:obj
  })
}

// 我的课程列表
export const reqMyCourseList = (obj) => {
  return Ajax({
    url:'/app/student/courseList',
    method:'get',
    params:obj
  })
}

// 数据字典: 项目 学科 地区 列表
export const reqDataList = (obj) => {
  return Ajax({
    url:'/app/dict/getList',
    method:'post',
    data:obj
  })
}

// 课程详情 列表
export const reqCourseDetail = (courseId) => {
  return Ajax({
    url:`/app/course/detail/${courseId}`,
    method:'get',
  })
}

// 获取订单详情
export const reqOrderDetail = (obj) => {
  return Ajax({
    url:`/web/order/create`,
    method:'post',
    data: obj
  })
}

// 我的订单
export const reqMyOrder = (data) => {
  return Ajax({
    url:`/app/order/list`,
    method:'get',
    params: data
  })
}

// 订单 待支付 已支付 已关闭 列表
export const reqOrderPaid = (data) => {
  return Ajax({
    url:`/app/order/detail`,
    method:'get',
    params: data
  })
}

// 倒计时结束更新订单状态  或  待支付页面取消订单操作  或  订单详情页面切换二维码或者支付方式的时候取消订单
export const reqUpdateOrder = (data) => {
  return Ajax({
    url:`/app/order/update`,
    method:'get',
    params: data
  })
}

// // 获取订单支付二维码
// export const reqQRCode = (data) => {
//   return Ajax({
//     url:`/web/order/create`,
//     method:'post',
//     data: data,
//     responseType: "blob",
//   })
// }

// // 继续支付 获取订单支付二维码
// export const reqContinuePay = (data) => {
//   return Ajax({
//     url:`/web/order/continue-pay`,
//     method:'get',
//     params: data,
//     responseType: "blob",
//   })
// }

// 检测此课程是否已有待支付的订单
export const reqIsHaveOrder = (data) => {
  return Ajax({
    url:`/app/order/check`,
    method:'get',
    params: data,
  })
}

// 用户是否完成付款
export const reqIsFinishPay = (data) => {
  return Ajax({
    url:`/web/order/status`,
    method:'get',
    params: data,
  })
}

// 课程分类
export const reqCategoryList = () => {
  return Ajax({
    url:`/app/course/category/title/list`,
    method:'get',
  })
}

// 课集列表
export const reqKejiList = (data) => {
  return Ajax({
    url:`/app/course/association/detail/${data}`,
    method:'get',
  })
}

// 获取视频播放id
export const reqVideoId = (data) => {
  return Ajax({
    url:`/sys/course/video/get-play-url/${data}`,
    method:'get',
  })
}

// 隐藏课程
export const reqHideCourse = (obj) => {
  return Ajax({
    url:`/app/course/manage/hide`,
    method:'post',
    data: obj
  })
}

// 隐藏的列表
export const reqHideList = (data) => {
  return Ajax({
    url:`/app/course/manage/hide/list`,
    method:'get',
    params: data,
  })
}

// 取消隐藏
export const reqCancleHide = (obj) => {
  return Ajax({
    url:`/app/course/manage/cancel/hide`,
    method:'post',
    data: obj
  })
}

// 会员卡片信息
export const reqCardInfo = () => {
  return Ajax({
    url:`/app/student/new/cardInfo`,
    method:'get',
  })
}