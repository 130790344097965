import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import '@/element'
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import 'element-ui/lib/theme-chalk/index.css';
import VueVirtualScroller from "vue-virtual-scroller";
Vue.use(VueVirtualScroller);

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
