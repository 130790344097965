export default [
  {
    path:'/',
    redirect:'/home'
  },
  {
    path:'/home',
    component:() => import('@/pages/Home') //本质就是在注册路由组件
    // component 注册的组件  不但可以写组件，还可以写一个函数
  },
  {
    path:'/order/my',
    component:() => import('@/pages/Order/My')
  },
  {
    path:'/order/detail',
    component:() => import('@/pages/Order/Detail')
  },
  {
    path:'/order/await',
    component:() => import('@/pages/Order/Await')
  },
  {
    path:'/order/paid',
    component:() => import('@/pages/Order/Paid')
  },
  {
    path:'/order/closed',
    component:() => import('@/pages/Order/Closed')
  },
  {
    path:'/order/member',
    component:() => import('@/pages/Order/Member')
  },
  {
    path:'/course/detail',
    component:() => import('@/pages/Course/Detail')
  },
  {
    path:'/course/play',
    component:() => import('@/pages/Course/Play')
  },
  {
    path:'/course/keji',
    component:() => import('@/pages/Course/Keji')
  },
  {
    path:'/course/hide',
    component:() => import('@/pages/Course/Hide')
  },
]