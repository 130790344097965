//user模块的小store
import {reqGetUserInfo, reqPhoneCode, reqUserLogin, reqUserLogout} from '@/api'
const state = {
  code:'',
  token:localStorage.getItem('TOKEN_KEY'), //或去不到拿到null
  userInfo:{}
}
const mutations = {
  RECEIVE_CODE(state,code){
    state.code = code
  },
  RECEIVE_TOKEN(state,token){
    state.token = token
  },
  RECEIVE_USERINFO(state,userInfo){
    state.userInfo = userInfo
  },

  RESET_TOKEN(state){
    state.token = ''
  },

  RESET_USERINFO(state){
    state.userInfo = {}
    state.token = ''
  }



}
const actions = {
  // 获取验证码
  async getCode({commit},phone){
    const result = await reqPhoneCode(phone)
    if(result.code === 0){
      commit('RECEIVE_CODE',result.code)
      return result.code
    }else{
      return Promise.reject('failed')
    }
  },
  //登录
  async userLogin({commit},userInfo){
    const result = await reqUserLogin(userInfo)
    if(result.code === 0){
      commit('RECEIVE_TOKEN',result.token)
      // 登录完成后，不但要把token保存在state（第一次），
      // 还要把token保存在localStorage
      localStorage.setItem('TOKEN_KEY',result.token)
      return result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },

  //根据token获取用户信息
  async getUserInfo({commit}){
    const result = await reqGetUserInfo()
    if(result.code === 0){
      commit('RECEIVE_USERINFO',result.result)
      return result.result
    }else{
      commit('RESET_USERINFO') //把state当中token和用户信息清除
      localStorage.removeItem('TOKEN_KEY')
      return Promise.reject(new Error('failed'))
    }
  },

  //用户信息获取失败，重置token,不但要把state里面清空，而且要清空localStorage里面
  async resetToken({commit}){
    commit('RESET_TOKEN')
    localStorage.removeItem('TOKEN_KEY')
  },


  //退出登录
  async userLogout({commit}){
    const result = await reqUserLogout()
    if(result.code === 0){
      commit('RESET_USERINFO') //把state当中token和用户信息清除
      localStorage.removeItem('TOKEN_KEY')
      return 'ok'
    }else{
      return Promise.reject(new Error('failed'))
    }
  },

}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}