import {reqCourseDetail,reqKejiList,reqVideoId,reqHideCourse,reqHideList,reqCancleHide} from '@/api'
const state = {
  courseDetailList: {},
  kejiList: [],
  hideList: [],
}
const mutations = {
  RECEIVE_DETAIL(state,list){
    state.courseDetailList = list
  },
  RECEIVE_KEJI(state,list){
    state.kejiList = list
  },
  RECEIVE_HIDELIST(state,list){
    state.hideList = list
  },

}
const actions = {
  async getCourseDetail({commit},id){
    const result = await reqCourseDetail(id)
    if(result.code === 0){
      commit('RECEIVE_DETAIL',result.result)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getKejiList({commit},id){
    const result = await reqKejiList(id)
    if(result.code === 0){
      commit('RECEIVE_KEJI',result.result)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getVideoId({commit},id){
    const result = await reqVideoId(id)
    if(result.code === 0){
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async hideCourse({commit},id){
    const result = await reqHideCourse(id)
    if(result.code === 0){
      return result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getHideList({commit},id){
    const result = await reqHideList(id)
    if(result.code === 0){
      commit('RECEIVE_HIDELIST',result.result)
      return result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async cancleHide({commit},id){
    const result = await reqCancleHide(id)
    if(result.code === 0){
      return result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}