// import {reqOrderDetail,reqMyOrder,reqOrderPaid,reqUpdateOrder,reqQRCode,reqContinuePay,reqIsHaveOrder,reqIsFinishPay} from '@/api'
import {reqOrderDetail,reqMyOrder,reqOrderPaid,reqUpdateOrder,reqIsHaveOrder,reqIsFinishPay,reqCardInfo} from '@/api'
import {reqQRCode,reqContinuePay} from '@/api/specialIndex'
const state = {
  orderDetail: [],
  myOrderList: [],
  orderPaid: [],
}
const mutations = {
  RECEIVE_ORDER_DETAIL(state,list){
    state.orderDetail = list
  },
  RECEIVE_MY_ORDER(state,list){
    state.myOrderList = list
  },
  RECEIVE_ORDER_PAID(state,list){
    state.orderPaid = list
  },
}
const actions = {
  async getOrderDetail({commit},obj){
    const result = await reqOrderDetail(obj)
    if(result.code === 0){
      commit('RECEIVE_ORDER_DETAIL',result.result)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getMyOrder({commit}, obj){
    const result = await reqMyOrder(obj)
    if(result.code === 0){
      commit('RECEIVE_MY_ORDER',result.result.list)
      return {list: result.result.list,total: result.result.totalPage};
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getOrderPaid({commit},data){
    const result = await reqOrderPaid(data)
    if(result.code === 0){
      commit('RECEIVE_ORDER_PAID',result.result)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async updateOrder({commit},data){
    const result = await reqUpdateOrder(data)
    if(result.code === 0){
      return result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getQRCode({commit},data){
    const result = await reqQRCode(data)
    return result
  },
  async continuePay({commit},data){
    const result = await reqContinuePay(data)
    return result
  },
  async isHaveOrder({commit},data){
    const result = await reqIsHaveOrder(data)
    if(result.code === 0){
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async isFinishPay({commit},data){
    const result = await reqIsFinishPay(data)
    if(result.code === 0){
      return result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getCardInfo({commit}){
    const result = await reqCardInfo()
    if(result.code === 0){
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}