import {reqDataList,reqCategoryList} from '@/api'
const state = {
  urlList: [],
  examIdList: [],
  categoryList: [],
  titleIdList: [],
  areaList: [],
  currentCategory: [],
}
const mutations = {
  RECEIVE_URL(state,list){
    state.urlList = list
  },
  RECEIVE_EXAM(state,list){
    state.examIdList = list
  },
  RECEIVE_CATEGORY(state,list){
    state.categoryList = list;
  },
  RECEIVE_AREA(state,list){
    state.areaList = list
  },
  RECEIVE_TITLEID(state,list){
    state.titleIdList = list
  },
  RECEIVE_CURRENTCATEGORY(state,list){
    state.currentCategory = list
  },
}
const actions = {
  async getUrlList({commit},obj){
    const result = await reqDataList(obj)
    if(result.code === 0){
      commit('RECEIVE_URL',result.result)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getDataList({commit},obj){
    const result = await reqDataList(obj)
    if(result.code === 0){
      commit('RECEIVE_EXAM',result.result)
      // commit('RECEIVE_SUBJECT',arr2)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getAreaList({commit},obj){
    const result = await reqDataList(obj)
    if(result.code === 0){
      let arr = [{
        id: '',
        name: '全部'
      }];
      result.result.forEach((item,index)=>{
        arr.push({
          id:item.id,
          name:item.name
        })
      })
      commit('RECEIVE_AREA',arr)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },
  async getCategoryList({commit}){
    const result = await reqCategoryList()
    if(result.code === 0){
      let arr = [];
      result.result.forEach((item,index)=>{
        arr.push({
          id:item.code,
          name:item.name,
          type:item.type,
          titleList:item.titleList
        })
      })
      let arr2 = [
        {
          id: '',
          name: '全部'
        }
      ]
      arr[0].titleList.forEach((item,index)=>{
        arr2.push({
          id:item.id,
          name:item.name
        })
      })
      commit('RECEIVE_CATEGORY',arr)
      commit('RECEIVE_CURRENTCATEGORY',arr[0].type)
      commit('RECEIVE_TITLEID',arr2)
      return result.result
    }else{
      return Promise.reject(new Error('failed'))
    }
  },

}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}